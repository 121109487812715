export default defineAppConfig({
    ui: {
        primary: "indigo",
        gray: "cool",
        input: {
            default: {
                size: "md",
            },
        },
        select: {
            default: {
                size: "md",
            },
        },
        modal: {
            container: "items-start sm:items-center",
        },
        table: {
            td: {
                base: "whitespace-nowrap [&:last-child]:text-right",
            },
            th: {
                base: "text-left rtl:text-right [&:last-child]:text-right",
            },
        },
    },
});
