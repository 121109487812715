import type { FetchOptions } from "ofetch";

// TODO: how to properly type options so that it's not "any" and that it doesn't cause complaints when the function is called
export async function $fetchWithToken<T>(
    url: string,
    options?: any
): Promise<T> {
    try {
        const { getFirebaseIdToken } = useFirebaseAuth();
        const idToken = await getFirebaseIdToken();

        const optionsWithToken: FetchOptions = {
            ...options,
            headers: {
                ...options?.headers,
                idToken: idToken,
            },
        } as FetchOptions;

        return $fetch<T>(url, optionsWithToken);
    } catch (error) {
        console.error("Error in token$fetch:", error);
        throw error;
    }
}
