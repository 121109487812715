// stores/partner/index.ts
import { defineStore } from "pinia";
import { ref, computed } from "vue";

interface Partner {
    id: string;
    name: string;
    type: "hospital" | "clinic" | "physician_office" | "laboratory";
    address: {
        street: string;
        city: string;
        state: string;
        postal_code: string;
        country: string;
    };
    contact: {
        email: string;
        phone: string;
        fax?: string;
    };
    tax_id: string;
    insurance_networks: string[];
    specialties?: string[];
    status: "active" | "suspended" | "pending";
    settings: {
        notification_preferences: {
            email: boolean;
            sms: boolean;
        };
        default_claim_settings?: Record<string, any>;
    };
}

export const usePartnerStore = defineStore(
    "partner",
    () => {
        // state
        const partner = ref<Partner | null>(null);
        const isLoading = ref(false);
        const error = ref<string | null>(null);

        // getters
        const isPartnerLoaded = computed(() => !!partner.value);
        const partnerId = computed(() => partner.value?.id);
        const partnerName = computed(() => partner.value?.name);
        const isActive = computed(() => partner.value?.status === "active");
        const acceptedInsuranceNetworks = computed(
            () => partner.value?.insurance_networks || []
        );

        // actions
        async function loadPartner() {
            if (partner.value) return;

            isLoading.value = true;
            try {
                const data = await useFetch("/api/partner");

                if (data.error.value) throw data.error.value;
                partner.value = data.data.value;
            } catch (e) {
                error.value = e.message;
                const auth = useAuthStore();
                auth.logout();
            } finally {
                isLoading.value = false;
            }
        }

        async function updatePartner(updates: Partial<Partner>) {
            isLoading.value = true;
            try {
                const data = await useFetch("/api/partner", {
                    method: "PUT",
                    body: updates,
                });

                if (data.error.value) throw data.error.value;
                partner.value = data.data.value;
            } catch (e) {
                error.value = e.message;
            } finally {
                isLoading.value = false;
            }
        }

        function clearPartner() {
            partner.value = null;
            error.value = null;
        }

        return {
            // state
            partner,
            isLoading,
            error,

            // getters
            isPartnerLoaded,
            partnerId,
            partnerName,
            isActive,
            acceptedInsuranceNetworks,

            // actions
            loadPartner,
            updatePartner,
            clearPartner,
        };
    },
    {
        persist: {
            paths: ["partner"],
        },
    }
);

// export const usePartnerStore = defineStore("partnerStore", () => {
//     // State
//     const partner = ref(null);

//     // const updateAccountIsActive = ref(false);
//     // const modalAddClientIsActive = ref(false);
//     // const modalAddInsuranceIsActive = ref(false);

//     // Getters
//     const partnerId = computed(() => {
//         return partner.value?.id || null;
//     });

//     // Actions

//     function $reset() {
//         partner.value = null;
//     }

//     return {
//         partner,
//         partnerId,

//         $reset,
//     };
// });
